import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/Button/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledFindReseller = styled.div`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const StyledBackgroundImage = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Wrapper = styled.div`
  padding: 50px 0 10px 0;
  height: 320px !important;
  width: 100% !important;
  position: relative;

  @media screen and (max-width: 1200px) {
    height: 300px !important;
  }

  @media screen and (max-width: 991px) {
    height: 220px !important;
  }

  @media screen and (max-width: 768px) {
    height: 160px !important;
  }

  @media screen and (max-width: 400px) {
    height: 120px !important;
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  z-index: 2;
`

interface FindResellerProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_FindReseller
}

const FindReseller: React.FC<FindResellerProps> = ({ fields }) => (
  <StyledFindReseller className="py-5">
    <div className="container">
      <ParseContent className="pb-4" content={fields.description} />
      <Wrapper>
        <StyledBackgroundImage image={fields?.image} alt="" />
        <div className="h-100 d-flex justify-content-center align-items-end">
          <StyledButtonDefault to={fields?.link?.url || '/404'}>
            {fields?.link?.title}
          </StyledButtonDefault>
        </div>
      </Wrapper>
    </div>
  </StyledFindReseller>
)

export default FindReseller
